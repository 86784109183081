@import "../../utils/variables.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../../assets/images/get_in_touch_bg.png");
    background-position: center;
    background-size: cover;
    padding-block: 3em;
    color: white;

    position: relative;
    .closeBtn {
        position: absolute;
        right: 1em;
        top: 1em;
        border-radius: 50%;
        background-color: white;
        width: 30px;
        height: 30px;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    header {
        div.title {
            font-size: 1.4rem;
            font-weight: 500;
            margin-bottom: 0.5em;
            text-align: center;
        }
        div.subtitle {
            margin-bottom: 2em;
            text-align: center;
        }

        form {
            .row {
                display: flex;
                margin-bottom: 1em;

                p {
                    font-size: 0.8rem;
                    color: rgb(255, 187, 187);
                    margin-top: 0.4em;
                    font-weight: 500;
                }
            }

            input {
                height: 35px;
                width: 20em;
                margin-right: 1em;
                border-radius: 5px;
                border: none;
                padding-left: 15px;
            }

            .button {
                text-align: center;
                margin-top: 2em;

                button:disabled {
                    background-color: #9e9e9e;
                    border: none;
                    color: black;
                }

                button {
                    border: 1px solid white;
                    color: white;
                    border-radius: 5px;
                    background-color: transparent;
                    width: 20em;
                    height: 40px;

                    &:hover {
                        background-color: white;
                        color: $themePurple;
                    }
                }
            }

            div.apiResp {
                width: 100%;
                text-align: center;
                margin-top: 0.5em;
                font-size: 0.9rem;
            }
            div.apiSuccess {
                color: rgb(165, 250, 125);
            }
            div.apiError {
                color: rgb(255, 187, 187);
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .wrapper {
        form {
            .row {
                flex-direction: column;
                margin-bottom: 0em !important;

                input {
                    margin-bottom: 1.5em;
                    margin-right: 0;
                }
            }
        }
    }
}
