@import "~utils/variables";

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: calc(5em + #{$navbarHeight});

    .img404 {
        width: 400px;
        margin-bottom: 3em;
    }

    section.text {
        width: 100%;

        h3 {
            text-align: center;
            font-size: 2rem;
            width: 100%;
        }

        div {
            margin-block: 1em;
            color: rgba(1, 1, 1, 0.5);

            display: flex;
            justify-content: center;

            width: 100%;

            p {
                text-align: center;
                max-width: 40ch;
            }
        }

        button {
            margin: 0 auto;
            margin-top: 1em;
        }
    }
}
