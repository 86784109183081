@import "../../utils/variables.scss";

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px);
    z-index: 12;

    .content {
        background-color: #ffffff;
        min-width: 400px;
        border-radius: 5px;
        .modalController {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            margin-bottom: 20px;
            p {
                color: $themePurple;
                font-size: 20px;
                font-weight: 700;
            }
            button {
                border: none;
                outline: none;
                cursor: pointer;
                background-color: #ffffff;
                svg {
                    stroke: #1e1e1e;
                    stroke-width: 3px;
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .backdrop {
        .content {
            min-width: 90%;
            max-width: 95%;
        }
    }
}
