$themePurple: #9456c8;
$sidebarWidth: 260px;
$navbarHeight: 70px;
$wvpDropWidth: 205px;
$wvpDropHeight: 110px;
$wvpDropMargin: 10px;
$dashNavHeight: 70px;

$career: #47c788;
$aptitude: #f48382;
$personality: #8c9af3;
$wvp: #6c4d6c;
$eq: #ffc034;
