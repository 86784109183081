@import "../../utils/variables.scss";

.wrapper {
    padding-top: calc(#{$navbarHeight});

    .header {
        background-image: linear-gradient(to left, #c682ff, #9456c8);
        height: 3em;
        padding-inline: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 1.8rem;
        font-weight: 600;
    }

    .main {
        padding-top: 2em;
        display: flex;
        justify-content: space-between;

        .right {
            padding-left: 2em;
            width: 60%;
        }

        .left {
            width: 40%;

            .info {
                min-width: 120px;
                max-width: 280px;
                margin-bottom: 3em;

                color: #707070;

                article {
                    display: flex;
                    margin-bottom: 1em;

                    div.icon {
                        margin-right: 1em;
                    }

                    svg {
                        color: $themePurple;
                    }
                }
            }

            .title {
                margin-bottom: 1em;
                margin-top: 1em;
                font-weight: 500;
            }

            .buttons {
                display: flex;
                flex-direction: column;

                button {
                    background-color: $themePurple;
                    color: white;
                    height: 40px;
                    margin-bottom: 0.5em;
                    border-radius: 5px;
                    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
                }

                button:first-child {
                    background-color: #25d366;
                }
            }
        }
    }

    .getInTouch {
        width: 100%;
        margin-top: 5em;
    }
}

@media screen and (max-width: 1200px) {
    .wrapper {
        .main {
            flex-direction: column;

            .left {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 3em;

                .buttons {
                    width: 100% !important;

                    button {
                        width: 100% !important;
                    }
                }
            }

            .right {
                padding-left: 0;
                width: 100%;
                min-height: 500px;
            }
        }
    }
}
